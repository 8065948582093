import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, FormHelperText, Stack, styled, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import * as Yup from 'yup';
// import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
  RHFUploadMultiFile,
  RHFUploadSingleFile,
} from '../../../components/hook-form';

import axios from '../../../utils/axios';

const UploadFIRCs = ({ userId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [checkingData, setCheckingData] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));

  const defaultValues = {
    additionalDoc: null,
  };
  const DocumentSchema = Yup.object().shape({
    additionalDoc: Yup.mixed(),
  });
  const methods = useForm({
    resolver: yupResolver(DocumentSchema),
    defaultValues,
  });
  const { handleSubmit, register, setValue, reset, watch } = methods;

  const values = watch();

  const handleNext = async (data) => {
    try {
      setSubmitting(true);
      console.log('Data', data);

      const bodyFormData = new FormData();
      // Make api call and then move ahead
      // Object.keys(data).map((key, i) => {
      //   if (data[key] !== null) {
      //     bodyFormData.append(`${key}[]`, data[key]);
      //   }

      //   return key;
      // });
      if(!data.additionalDoc.length || (data.additionalDoc.length === 0)) {
        enqueueSnackbar('Please upload atleast one file.', { variant: 'error' });
      } else {
        data.additionalDoc.forEach((file, i) => {
          bodyFormData.append(`additionalDoc`, file);
        });
        const res = await axios.post(`request/bulkUploadFIRC`, bodyFormData, {
          headers: { 'Content-Type': `multipart/form-data;` },
        });

        enqueueSnackbar('Files uploaded successfully.');
        reset();
        // navigate(PATH_AUTH.login, { replace: true });
      }
      
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message || 'Something went wrong', { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const additionalDoc = values.additionalDoc || [];

      setValue('additionalDoc', [
        ...additionalDoc,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.additionalDoc]
  );

  const handleRemoveAll = () => {
    setValue('additionalDoc', []);
  };

  const handleRemove = (file) => {
    const filteredItems = values.additionalDoc && values.additionalDoc?.filter((_file) => _file !== file);

    setValue('additionalDoc', filteredItems);
  };

  const handleUpload = () => {
    handleSubmit(handleNext)();
  };

  return (
    <Box>
      {checkingData ? (
        <Box display={'flex'} justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleNext)}>
          <Typography variant="h6" sx={{ fontWeight: 'light' }}>
            Upload All FIRCs (Bulk Upload)
          </Typography>
          <Stack mt={4} direction={{ xs: 'column', sm: 'column' }} spacing={3}>
            <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} gap={1}>
              <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
                <LabelStyle>FIRCs </LabelStyle>

                <RHFUploadMultiFile
                  showPreview
                  name="additionalDoc"
                  label="FIRCs"
                  accept="application/pdf"
                  maxSize={50 * 1024 * 1024}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  // onUpload={handleUpload}
                />
              </Box>
            </Box>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', pt: 2 }}>
            <LoadingButton loading={submitting} type="submit" variant="contained">
              Submit
            </LoadingButton>
          </Box>
        </FormProvider>
      )}
    </Box>
  );
};

UploadFIRCs.propTypes = {
  setActiveStep: PropTypes.func,
  activeStep: PropTypes.number,
};

export default UploadFIRCs;

import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, IconButton, Stack, Toolbar, Typography, Tooltip, DialogActions } from '@mui/material';
// components
import Logo from '../components/Logo';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH } from '../routes/paths';
import Iconify from '../components/Iconify';
import { DialogAnimate } from '../components/animate';
import UploadFIRCs from '../pages/superuser/Users/UploadFIRCs';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SuperUserLayout() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [openUploadFIRCsDialog, setOpenUploadFIRCsDialog] = useState(false);
  return (
    <>
      <AppBar sx={{ backgroundColor: '#fff' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* <Stack direction={'row'} justifyContent={'space-between'}> */}
          <Stack alignItems={'center'} direction={'row'} gap={2}>
            <Logo />
            <Typography color="text.secondary" variant="h6">
              Admin Dashboard
            </Typography>
          </Stack>

          <Stack direction={'row'} gap={1}>
            <Button variant="text" LinkComponent={NavLink} to="qualifiedLeads">
              Qualified Leads
            </Button>
            <Button variant="text" LinkComponent={NavLink} to="/adminDashboard">
              Users
            </Button>
            <Button variant="text" LinkComponent={NavLink} to="requests/all?mode=quick">
              Requests
            </Button>
            <Button variant="text" onClick={() => {
                  setOpenUploadFIRCsDialog(true);
                }}>
              Upload FIRAs
            </Button>
            <Button variant="text" LinkComponent={NavLink} to="support">
              Support Tickets
            </Button>
            <IconButton
              onClick={async () => {
                await logout();
                navigate(PATH_AUTH.login, { replace: true });
              }}
              edge="end"
              color="primary"
              size="large"
            >
              <Iconify icon={'material-symbols:logout'} />
            </IconButton>
          </Stack>
          {/* </Stack> */}
        </Toolbar>
      </AppBar>
      <Outlet />
      <DialogAnimate open={openUploadFIRCsDialog}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton
                color="inherit"
                onClick={() => {
                  setOpenUploadFIRCsDialog(false);
                }}
              >
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', p: 3 }}>
            <UploadFIRCs />
          </Box>
        </Box>
      </DialogAnimate>
    </>
  );
}

import { useEffect, useMemo, useState } from 'react';
// @mui
import {
  Stack,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import axios from '../../../utils/axios';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DOCS } from '../../../routes/paths';
// assets
import { DocIllustration } from '../../../assets';
import AddWalletForm from './AddWalletForm';
import ActiveWallets from './ActiveWallets';
import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function AddWallet({ showPopUp, setShowPopUp, customNavConfig }) {
  const { user } = useAuth();
  console.log(user);
  const isDesktop = useResponsive('up', 'md');
  console.log('isDesktop in Add Wallet', isDesktop);
  const [currentTab, setCurrentTab] = useState(!isDesktop && Boolean(customNavConfig) ? 'Current' : 'Add');
  useEffect(() => {
    setCurrentTab(!isDesktop && Boolean(customNavConfig) ? 'Current' : 'Add');
  }, [isDesktop]);

  const TABS = [
    ...(!isDesktop && Boolean(customNavConfig)
      ? [
          {
            value: 'Current',

            component: (
              <ActiveWallets
                setShowPopUp={setShowPopUp}
                customNavConfig={customNavConfig}
                setCurrentTab={setCurrentTab}
              />
            ),
            disabled: false,
            label: 'Active Currencies',
          },
        ]
      : []),
    {
      value: 'Add',

      component: <AddWalletForm />,
      disabled: false,
      label: 'Add New Currency',
    },
  ];

  return (
    <Dialog
      open={showPopUp}
      onClose={() => setShowPopUp(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ px: 2, py: 4, minHeight: '450px' }}>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {TABS.map((tab) => (
            <Tab disableRipple key={tab.value} disabled={tab.disabled} label={tab.label} value={tab.value} />
          ))}
        </Tabs>

        <Box sx={{ mb: 2.5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          // let msg = 
          // console.log(user.active, user.approved, user?.org?.xflowId, user?.isSuperuser)
          if(isMatched && !tab.disabled && !user.approved) {
            return <>
              You can add other currencies from here once your Mulya account is activated.
            </>
          }
          // // prev - only allowing non cashfree to add. Now - only allowing xflow to add directly
          return isMatched && !tab.disabled && ((user?.isSuperuser || (user.active && user.approved && user?.org?.xflowId)) ? <Box key={tab.value}>{tab.component}</Box> : 
          // return isMatched && !tab.disabled && ((user?.isSuperuser || user?.org?.cashfree) ? <Box key={tab.value}>{tab.component}</Box> : 
            <>
              Please <a href="https://wa.me/919429692995/?text= Hey! I want to enable additional currencies." target="_blank" rel="noopener noreferrer">click here</a> to enable more currencies. We might need additional documents to proceed.
            </>
          );
        })}
      </Box>
    </Dialog>
  );
}

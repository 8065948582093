import { Box, Button, DialogActions, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import React from 'react';
import Iconify from '../../../components/Iconify';
import { DialogAnimate } from '../../../components/animate';
import AddWallet from './AddWallet';

const ActiveWallets = ({ customNavConfig, setCurrentTab, setShowPopUp }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden', p: 2 }}>
      <Box
        component={'div'}
        sx={{
          height: '250px',
          overflowY: 'scroll',
        }}
      >
        <Grid container spacing={2}>
          {customNavConfig
            .find((config) => config.subheader === 'Accounts')
            ?.items?.map((v) => (
              <Grid item xs={12}>
                <Paper
                  onClick={() => {
                    navigate(v.path);
                    setShowPopUp(false);
                  }}
                  elevation={4}
                  sx={{ padding: '10px' }}
                >
                  <Box
                    component="div"
                    display="flex"
                    justifyContent={'flex-start'}
                    alignItems="center"
                    flexDirection={'row'}
                  >
                    <Box>{v.icon}</Box>
                    <Box sx={{ ml: 2 }}>
                      <Typography>{v.name}</Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Box>
      <Stack
        spacing={3}
        sx={{ px: { md: 5, xs: 0 }, pb: { md: 5, xs: 0 }, mt: 3, width: 1, textAlign: 'center', display: 'block' }}
      >
        <Button
          onClick={() => {
            setCurrentTab('Add');
          }}
          startIcon={<Icon icon="carbon:add" />}
          size="medium"
          variant="contained"
        >
          Add New Currency
        </Button>
      </Stack>
    </Box>
  );
};

export default ActiveWallets;

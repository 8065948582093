import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import { PATH_AUTH } from '../routes/paths';
import axios from '../utils/axios';

const OnboardGuard = ({ children }) => {
  // Here we write the logic if a user is logging in first time then we must onboard else redirect to children
  // children is dashboard as of now

  const { user } = useAuth();
  console.log('user obj ', user);
  if (user.onboardingStatus === 1) {
    return <Navigate to={'/onboarding'} />;
  }

  return <>{children}</>;
};
OnboardGuard.propTypes = {
  children: PropTypes.node,
};
export default OnboardGuard;

import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Fab, Link, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    // marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_WIDTH,
    // }),
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: NAVBAR.DASHBOARD_WIDTH,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 54,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const { user, initialize } = useAuth();

  useEffect(() => {
    initialize();
  }, []);

  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { md: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box>
      {user.onboardingStatus !== 4 && (
        <Box sx={{ width: '100%', backgroundColor: 'primary.main', zIndex: 1210, position: 'fixed', top: 0, py: 0.5 }}>
          <Typography color="white" variant="body2" align="center">
            {user.onboardingStatus === 1 ? (
              <>
                Please complete the KYC to start accepting payments. Click{' '}
                <Link
                  onClick={() => {
                    navigate(`/dashboard/profile`);
                  }}
                  sx={{
                    cursor: 'pointer',
                    color: 'white',
                    textDecorationLine: 'underline',
                  }}
                >
                  here
                </Link>{' '}
                to proceed.
              </>
            ) : (
              <> Thanks for completing the KYC. Your virtual accounts would be ready in 2-3 working days.</>
            )}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
        {isDesktop && (
          <Fab
            href={`https://wa.me/919429692995/?text= Hey! I'm facing an issue on the Mulya App.`}
            target="_blank"
            rel="noopener"
            size="medium"
            variant="extended"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              borderRadius: '8px',
              zIndex: 99999,
            }}
          >
            <Icon icon="akar-icons:whatsapp-fill" />
            Help
          </Fab>
        )}
      </Box>
    </Box>
  );
}

import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log('Time left for token expiry', timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log('Token Expired');
    // You can do what ever you want here, like show a notification
    localStorage.removeItem('accessToken');
    window.location.reload();
  }, timeLeft);
};

// ----------------------------------------------------------------------

const setSession = (accessToken, ip = null, timezone = null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    // ! set axios header
    axios.defaults.headers.common.Authorization = accessToken;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }

  if (ip) {
    sessionStorage.setItem('ip', ip);
    axios.defaults.headers.common['X-Forwarded-For'] = ip;
  }
  if (timezone) {
    sessionStorage.setItem('timezone', timezone);
    axios.defaults.headers.common['X-Timezone'] = timezone;
  }
};

export { isValidToken, setSession, verify, sign };

import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import getCookie from '../utils/getCookie';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      let ip = window.sessionStorage.getItem('ip');
      let timezone = window.sessionStorage.getItem('timezone');
      if (!ip) {
        try {
          const geoRes = await axios.get(
            `${process.env.REACT_APP_GEOLOC_ENDPOINT}/ipgeo?apiKey=${process.env.REACT_APP_GEOLOCATION_KEY}`
          );

          const { time_zone: tz } = geoRes.data;
          timezone = tz.name;
          ip = geoRes.data.ip;
        } catch (error) {
          // Catching IP-GEO API error but no need to handle it!
          console.error(error);
        }
      }

      if (accessToken) {
        setSession(accessToken, ip); // * Adds Access Token to AXIOS HEADER

        const response = await axios.get('/user/profile');
        console.log('INIT', response);
        const { details: user } = response.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        // Fetch IP and store

        if (ip) {
          setSession(null, ip, timezone);
        }

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      setSession(null, null, null);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (email, password) => {
    const response = await axios.post('/login', {
      email,
      password,
    });
    const { token: accessToken, user } = response.data.details;
    // const geoRes = await axios.get(
    //   `${process.env.REACT_APP_GEOLOC_ENDPOINT}/ipgeo?apiKey=${process.env.REACT_APP_GEOLOCATION_KEY}`
    // );
    // const { ip, time_zone: tz } = geoRes.data;
    // const timezone = tz.name;
    const ip = window.localStorage.getItem('ip');
    const timezone = window.localStorage.getItem('timezone');

    console.log('Login', response);
    if (accessToken) {
      setSession(accessToken, ip, timezone);
      const response = await axios.get('/user/profile');
      console.log('Login INIT', response);
      const { details: user } = response.data;

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } else {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    // setSession(accessToken);
    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user,
    //   },
    // });
  };

  const register = async (email, password, phoneNumber, countryCode, companyName) => {
    const reqObj = {
      email,
      password,
      phoneNumber,
      countryCode,
      companyName,
    };
    if (!companyName) delete reqObj.companyName;

    // if there is referredBy cookie than add it to request object;
    const referredBy = getCookie('referredBy');
    if (referredBy) {
      reqObj.referredBy = referredBy;
    }

    // If there's source in the URL
    const source = getCookie('source');
    if (source) {
      reqObj.source = source;
    }

    const response = await axios.post('/user/register', reqObj);
    console.log('Register', response);
    // Details Contain Registered User Object
    const { user, token } = response.data.details;

    // const geoRes = await axios.get(
    //   `${process.env.REACT_APP_GEOLOC_ENDPOINT}/ipgeo?apiKey=${process.env.REACT_APP_GEOLOCATION_KEY}`
    // );
    // const { ip, time_zone: tz } = geoRes.data;
    // const timezone = tz.name;
    const ip = window.localStorage.getItem('ip');
    const timezone = window.localStorage.getItem('timezone');
    setSession(token, ip, timezone);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import ComingSoon from '../pages/ComingSoon';
// layouts

import DashboardLayout from '../layouts/dashboard';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import OnboardGuard from '../guards/OnboardGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import SuperuserGuard from '../guards/SuperuserGuard';
import OnboardCompleteGuard from '../guards/OnboardCompleteGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import SuperUserLayout from '../layouts/SuperUserLayout';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'passwordReset', element: <PasswordResetEmail /> },
        { path: 'passwordResetSuccessful', element: <PasswordResetSuccessfull /> },
        { path: 'confirm-account-change', element: <ConfirmVirtualAccountChange /> },
        { path: 'confirm-account-change-confirmed', element: <ConfirmVirtualAccountChangeConfirmed /> },
        { path: 'transaction-timeline/:reqId', element: <TransactionTimelineWithoutLogin /> },
        { path: 'confirmOTP/:mode/:user/:otp', element: <ConfirmOTP /> },
        { path: 'paymentGatewayTransactionStatus', element: <PaymentGatewayAfterPayment /> },
        { path: 'confirmWithdrawal/:id', element: <ConfirmWithdrawal /> },
      ],
    },
    // Onboarding Routes

    // {
    //   path: 'onboarding',
    //   element: (
    //     <AuthGuard>
    //       <SuperuserGuard>
    //         <OnboardCompleteGuard>
    //           <Onboarding />
    //         </OnboardCompleteGuard>
    //       </SuperuserGuard>
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: 'adminDashboard',
    //   element: (
    //     <AuthGuard>
    //       <RoleBasedGuard accessibleRoles={['superuser', 'admin']}>
    //         <SuperuserDashboard />
    //       </RoleBasedGuard>
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: 'adminDashboard/edit/:id',
    //   element: (
    //     <AuthGuard>
    //       <RoleBasedGuard accessibleRoles={['superuser', 'admin']}>
    //         <EditOrg />
    //       </RoleBasedGuard>
    //     </AuthGuard>
    //   ),
    // },

    {
      path: 'adminDashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['superuser', 'admin']}>
            {/* <LogoOnlyLayout /> */}
            <SuperUserLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <SuperuserDashboard />, index: true },
        { path: 'edit/:id', element: <EditOrg /> },
        { path: 'vac/:id', element: <AddVAC /> },
        { path: 'requests/all', element: <AllRequests /> },
        { path: 'support', element: <SupportTickets /> },
        { path: 'qualifiedLeads', element: <QualifiedLeads /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <SuperuserGuard>
            <DashboardLayout />
          </SuperuserGuard>
        </AuthGuard>
      ),
      children: [
        // ! PATH_AFTER_LOGIN -> currently set to /dashboard/requests/all
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        { path: 'app', element: <LandingPage /> },
        { path: 'accounts/:ccy', element: <Wallet /> },

        { path: 'profile', element: <UserAccount /> },
        { path: 'profile/:tab', element: <UserAccount /> },

        // { path: 'booking', element: <GeneralBooking /> },

        // ! New Requests Route
        {
          path: 'requests',
          children: [
            { element: <Navigate to="/dashboard/requests/all" replace />, index: true },
            { path: 'all', element: <RequestList /> },
            { path: 'new', element: <RequestCreate /> },
            { path: 'view/:reqId', element: <RequestInvoice /> },
          ],
        },
        {
          path: 'clients',
          children: [
            { element: <Navigate to="/dashboard/clients/all" replace />, index: true },
            { path: 'all', element: <ClientsList /> },
          ],
        },
        {
          path: 'referrals',
          children: [{ element: <Referrals />, index: true }],
        },
      ],
    },

    // ! For / redirect to /dashboard
    {
      path: '/',
      children: [
        { element: <Navigate to="/dashboard" redirect />, index: true },
        { path: '404', element: <Page404 /> },
      ],
    },
    // Without Auth Request Invoice
    { path: 'customer-invoice/:reqId', element: <RequestInvoice /> },
    { path: 'comingSoon', element: <ComingSoon /> },
    // Without Auth Payment Successfull Page
    { path: 'payment/:reqId', element: <Payment /> },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const PasswordResetEmail = Loadable(lazy(() => import('../pages/auth/PasswordResetEmail')));
const ConfirmVirtualAccountChange = Loadable(lazy(() => import('../pages/auth/ConfirmVirtualAccountChange')));
const ConfirmVirtualAccountChangeConfirmed = Loadable(lazy(() => import('../pages/auth/ConfirmVirtualAccountChangeConfirmed')));
const TransactionTimelineWithoutLogin = Loadable(lazy(() => import('../pages/auth/TransactionTimelineWithoutLogin')));
const ConfirmOTP = Loadable(lazy(() => import('../pages/auth/ConfirmOTP')));
const PaymentGatewayAfterPayment = Loadable(lazy(() => import('../pages/auth/PaymentGatewayAfterPayment')));
const ConfirmWithdrawal = Loadable(lazy(() => import('../pages/auth/ConfirmWithdrawal')));
// Dashboard

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const LandingPage = Loadable(lazy(() => import('../pages/dashboard/LandingPage')));

// New Imports
const RequestList = Loadable(lazy(() => import('../pages/requests/RequestList')));
const RequestCreate = Loadable(lazy(() => import('../pages/requests/RequestCreate')));

const PasswordResetSuccessfull = Loadable(lazy(() => import('../pages/auth/PasswordResetSuccessfull')));
const RequestInvoice = Loadable(lazy(() => import('../pages/requests/RequestInvoice')));
const Payment = Loadable(lazy(() => import('../pages/payments/Payment')));
const Wallet = Loadable(lazy(() => import('../pages/wallets/Wallet')));
const Onboarding = Loadable(lazy(() => import('../pages/onboarding/Onboarding')));

const SuperuserDashboard = Loadable(lazy(() => import('../pages/superuser/SuperuserDashboard')));
const EditOrg = Loadable(lazy(() => import('../pages/superuser/EditOrg')));
const AddVAC = Loadable(lazy(() => import('../pages/superuser/AddVAC')));
const AllRequests = Loadable(lazy(() => import('../pages/superuser/AllRequests')));
const SupportTickets = Loadable(lazy(() => import('../pages/superuser/SupportTickets')));
const QualifiedLeads = Loadable(lazy(() => import('../pages/superuser/QualifiedLeads')));
const ClientsList = Loadable(lazy(() => import('../pages/clients/ClientList')));

const Referrals = Loadable(lazy(() => import('../pages/referrals/Referrals')));
// 404 Page
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// @mui
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
};

function convertFileSizeError(message) {
  const messageArr = message.split(' ');
  const numberToReplace = messageArr[messageArr.length - 2];
  message = message.replace(numberToReplace, fData(numberToReplace));
  message = message.replace('bytes', '');
  return message;
}

export default function RejectionFiles({ fileRejections }) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>

            {errors.map((error) => (
              <Typography key={error.code} variant="caption" component="p">
                - {error.code === 'file-too-large' ? convertFileSizeError(error.message) : error.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}

import { useParams, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function MyAvatar({ name, ...other }) {
  const { pathname } = useLocation();
  const isCustomerInvoice = pathname.includes('customer-invoice');
  let { user } = useAuth();
  if(isCustomerInvoice) user = null;
  return (
    <Avatar
      src={user?.logo?.path ? `${process.env.REACT_APP_BACKEND_API_URL}/${user.logo.path}` : null}
      alt={user?.firstName}
      color={user?.logo?.path ? 'default' : 'primary'}
      hex={other.hex}
      {...other}
    >
      {user?.onboardingStatus === 4 ? (
        createAvatar(name?.replace('<', '') || user?.org?.businessName?.replace('<', '')).name
      ) : (
        <Iconify icon={'mdi:user'} />
      )}
    </Avatar>
  );
}

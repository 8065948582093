import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Link, DialogTitle, DialogActions, Dialog, Button } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   linkTo: '/',
  // },
  {
    label: 'Profile',
    linkTo: '/dashboard/profile',
  },
  // {
  //   label: 'Settings',
  //   linkTo: PATH_DASHBOARD.user.account,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const [showPopUp, setShowPopUp] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const deleteAccount = async () => {
    try {
      console.log(user);
      const response = await axios.post(`/user/restrictedActions/delete/${user._id.toString()}`);
      handleLogout();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to delete your account!', { variant: 'error' });
    }
  }

  const handleDeleteAccount = async () => {
    try {
      // await logout();
      // navigate(PATH_AUTH.login, { replace: true });

      // if (isMountedRef.current) {
      //   handleClose();
      // }
      setShowPopUp(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to delete your account!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.onboardingStatus === 4 && user?.org?.businessName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }}>
          <Link
            color="inherit"
            underline="none"
            target="_blank"
            rel="noopener"
            sx={{ width: 1 }}
            href={`https://wa.me/919429692995/?text=Hey! I'm facing an issue on the Mulya App.`}
          >
            Support
          </Link>
        </MenuItem>

        {/* Even here, we are not able to delete once the KYC is complete. Need to check this */}
        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDeleteAccount} sx={{ m: 1 }}>
          Delete Account
        </MenuItem> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
      <Dialog
        open={showPopUp}
        onClose={() => setShowPopUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ p: 4 }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography paragraph variant="h5" sx={{ my: 1 }}>
            Are you sure you want to delete your Mulya Account?
          </Typography>
          <Typography paragraph variant="body2">
            This action is IRREVERSIBLE!
          </Typography>
        </DialogTitle>
        <DialogActions>
          {(  // Here we can add the condition to allow the user to delete the account
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                deleteAccount();
                setShowPopUp(false);
              }}
              autoFocus
            >
              Delete My Account
            </Button>
          )}
          <Button color="primary" variant="text" onClick={() => setShowPopUp(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

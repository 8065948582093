import { useEffect, useMemo, useState } from 'react';
// @mui
import { Stack, Button, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Box, CircularProgress } from '@mui/material';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import axios from '../../../utils/axios';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DOCS } from '../../../routes/paths';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function AddWalletForm() {
  const { user } = useAuth();

  const [addingWallet, setAddingWallet] = useState(false);
  const [gettingCurrencies, setGettingCurrencies] = useState(false);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      currency: availableCurrencies[0]?.code || 'USD',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableCurrencies]
  );

  const DocumentSchema = Yup.object().shape({
    currency: Yup.string().required(),
  });

  const methods = useForm({
    resolver: yupResolver(DocumentSchema),
    defaultValues,
  });
  const { handleSubmit, register, setValue, control, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCurrencies]);

  const getAvailableCurrencies = async () => {
    try {
      // Fetch all system currencies - Y
      // Fetch wallets - X
      // Available currencies are : Y - X;
      setGettingCurrencies(true);
      const currencyRes = await axios.get(`/user/currency/all`);
      const systemCurrencies = currencyRes.data.details.filter((c) => c.active).map((c) => c.code);
      console.log('System Currencies', systemCurrencies);
      let walletRes;
      if(user.isSuperuser === true && window.location.href.includes('adminDashboard')) {
        walletRes = await axios.get(`/user/wallets?onlyVA=true&forAddWallet=true&orgId=${window.location.href.split('/')[window.location.href.split('/').length-1]}`);
      } else {
        walletRes = await axios.get('/user/wallets?onlyVA=true&forAddWallet=true');
      }
      
      const userWalletCurrencies = walletRes.data.details.map((obj) => obj.currency.code);
      console.log('User Wallet Currencies', userWalletCurrencies);

      const availableCurrencies = systemCurrencies.filter((curr) => !userWalletCurrencies.includes(curr) && curr!=='CAD');
      console.log('availableCurrencies ', availableCurrencies);
      availableCurrencies.sort();
      const availableCurrenciesWithName = availableCurrencies.map((code) =>
        currencyRes.data.details.find((c) => c.code === code)
      );
      availableCurrenciesWithName.sort((a, b) => a.name.localeCompare(b.name));
      console.log('Currencu with name ', availableCurrenciesWithName);
      setAvailableCurrencies(availableCurrenciesWithName);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message || 'Something went wrong', { variant: 'error' });
    } finally {
      setGettingCurrencies(false);
    }
  };

  useEffect(() => {
    getAvailableCurrencies();
  }, []);

  const postAddWallet = async (data) => {
    try {
      setAddingWallet(true);
      console.log(data);
      if(user.isSuperuser === true && window.location.href.includes('adminDashboard')) {
        const res = await axios.post(`/user/addWallet?orgId=${window.location.href.split('/')[window.location.href.split('/').length-1]}`, data);
      } else {
        const res = await axios.post(`/user/addWallet?orgId=${window.location.href.split('/')[window.location.href.split('/').length-1]}`, data);
      }
      
      // console.log('response add wallet ', res.data.details);
      enqueueSnackbar('Account added successfully.');

      // window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/dashboard/accounts/${data.currency}`;
      window.location.reload();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message || 'Something went wrong', { variant: 'error' });
    } finally {
      setAddingWallet(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(postAddWallet)}>
      <DialogTitle id="alert-dialog-title">
        <Typography paragraph variant="h5" sx={{ my: 1 }}>
          Select currency you want to add!
        </Typography>
      </DialogTitle>
      {gettingCurrencies ? <><DialogContent sx={{ mt: 5 }}><CircularProgress /></DialogContent></> :
      <>
        <DialogContent sx={{ mt: 5 }}>
          <Box sx={{ my: 1 }}>
            <RHFSelect name="currency" label="Currency">
              {availableCurrencies.map((curr, i) => (
                <option key={i} value={curr.code}>
                  {curr.name}
                </option>
              ))}
            </RHFSelect>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" loading={addingWallet} variant="contained" autoFocus>
            Add Account
          </LoadingButton>
        </DialogActions>
      </>}
    </FormProvider>
  );
}

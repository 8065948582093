import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  DialogActions,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import { Icon } from '@iconify/react';
// import CollapseButton from './CollapseButton';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import SvgIconStyle from '../../../components/SvgIconStyle';
import axios from '../../../utils/axios';
import AddWallet from './AddWallet';
import Iconify from '../../../components/Iconify';
import { DialogAnimate } from '../../../components/animate';
import WalletsDialog from './ActiveWallets';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------
const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loadingCurrencies, setLoadingCurrencies] = useState(false);

  const [customNavConfig, setCustomNavConfig] = useState(navConfig);

  const [openWalletDialog, setOpenWalletDialog] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    const getAvailableCurrencies = async () => {
      try {
        setLoadingCurrencies(true);
        const res = await axios.get('/user/wallets');
        console.log('Wallets data ', res.data.details);

        const allCurrencies = res.data.details.map((obj) => obj.currency);

        // We need title ,path , icon

        const balancesNavConfigIndex = navConfig.findIndex((config) => config.subheader === 'Accounts');
        let allNavConfigCurrencies = navConfig[balancesNavConfigIndex]?.items;

        allNavConfigCurrencies = allCurrencies.map((currency) => {
          return {
            title: currency.code,
            path: `/dashboard/accounts/${currency.code}`,
            icon: (
              <img
                alt="flag"
                src={`${process.env.REACT_APP_BACKEND_API_URL}/${currency.icon}`}
                style={{ width: '22px', height: '17px' }}
              />
            ),
            name: currency.name,
          };
        });
        navConfig[balancesNavConfigIndex].items = allNavConfigCurrencies;
        setCustomNavConfig(navConfig);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingCurrencies(false);
      }
    };
    // getAvailableCurrencies(); // Commenting to reduce API call time for sidebar account
  }, []);

  const [bottomNavValue, setBottomNavValue] = useState(() => {
    let val = 0;
    if (pathname.includes('requests')) {
      val = 1;
    } else if (pathname.includes('clients')) {
      val = 2;
    } else if (pathname.includes('app')) {
      val = 0;
    }
    return val;
  });
  const isDesktop = useResponsive('up', 'md');

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={customNavConfig} isCollapse={isCollapse} />
      <AddWallet customNavConfig={customNavConfig} showPopUp={openWalletDialog} setShowPopUp={setOpenWalletDialog} />
      {/* <Stack
        spacing={3}
        sx={{ px: { md: 5, xs: 0 }, pb: { md: 5, xs: 0 }, mt: 0, width: 1, textAlign: 'center', display: 'block' }}
      >
        <Button
          onClick={() => {
            setOpenWalletDialog(true);
          }}
          startIcon={<Icon icon="carbon:add" />}
          size="medium"
          variant="contained"
        >
          Add Account
        </Button>
      </Stack> */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && !loadingCurrencies && (
        // <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
        //   {renderContent}
        // </Drawer>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 99999 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={bottomNavValue}
            onChange={(event, newValue) => {
              setBottomNavValue(newValue);
            }}
          >
            <BottomNavigationAction
              onClick={() => {
                navigate('/dashboard/app');
              }}
              icon={getIcon('ic_dashboard')}
              sx={{ p: 1 }}
            />
            <BottomNavigationAction
              onClick={() => {
                navigate('/dashboard/requests/all');
              }}
              icon={getIcon('ic_reciept')}
              sx={{ p: 1 }}
            />
            <BottomNavigationAction
              onClick={() => {
                navigate('/dashboard/clients/all');
              }}
              icon={getIcon('ic_users')}
              sx={{ p: 1 }}
            />

            {/* <BottomNavigationAction
              onClick={() => {
                // Open popup
                setOpenWalletDialog(true);
              }}
              icon={getIcon('ic_wallet')}
              sx={{ pb: 2 }}
            /> */}
            <BottomNavigationAction
              href={`https://wa.me/919429692995/?text= Hey! I'm facing an issue on the Mulya App.`}
              target="_blank"
              rel="noopener"
              icon={getIcon('ic_whatsapp')}
              sx={{ p: 1 }}
            />
            <AddWallet
              customNavConfig={customNavConfig}
              showPopUp={openWalletDialog}
              setShowPopUp={setOpenWalletDialog}
            />
          </BottomNavigation>
        </Paper>
      )}

      {isDesktop && !loadingCurrencies && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
              ...(user.onboardingStatus !== 4 && { top: 20 }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
